/* eslint-disable @atlaskit/editor/no-re-export */
// Entry file in package.json

const clPrefix = 'ak-renderer-';

export const RendererCssClassName = {
	DOCUMENT: `${clPrefix}document`,
	EXTENSION: `${clPrefix}extension`,
	EXTENSION_CENTER_ALIGN: `${clPrefix}extension-center-align`,
	EXTENSION_OVERFLOW_CONTAINER: `${clPrefix}extension-overflow-container`,
	NUMBER_COLUMN: `${clPrefix}table-number-column`,
	SORTABLE_COLUMN_WRAPPER: `${clPrefix}tableHeader-sortable-column__wrapper`,
	SORTABLE_COLUMN: `${clPrefix}tableHeader-sortable-column`,
	SORTABLE_COLUMN_ICON_WRAPPER: `${clPrefix}tableHeader-sorting-icon__wrapper`,
	SORTABLE_COLUMN_NO_ORDER: `${clPrefix}tableHeader-sorting-icon__no-order`,
};
